.loginContainer{
  width: 100%;
  height: 100vh;
  background: #ffffff;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loginBg{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 200;
}
.loginBg img{
  width: auto;
  height: 100%;
}
.loginCenter{
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 500;
}
.loginLogo{
  width: 189px;
  height: 119px;
  background: url(../../images/loginlogo.png) no-repeat center center;
}
.form_box{
  width: 420px;
  height: 350px;
}

.login_enter_box{
  width: 420px;
  height: 60px;
  border-radius:4px ;
  margin-bottom: 29px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid #DCE1E5;
}
.login_enter_box .ant-form-item-explain{
  left: -50px;
  bottom: -25px;
}
.ant-form-item-has-error .ant-input-affix-wrapper{
  /* border: none !important; */
}
.login_enter_box .ant-form-item {
  margin-right: 0px !important;
}

.ant-form-inline .ant-form-item-with-help{
  margin-bottom: 0px !important;
}

.login_enter_icon{
  width: 50px;
  height: 60px;
  color: rgba(94, 121, 236, 1);
  font-size: 20px !important;
  font-weight: bold;
  line-height: 60px;
  text-align: center;
}
.login_enter_box_input{
  width: 360px;
  height: 60px;
  border: none;
  background: none !important;
  font-size: 16px !important;
  box-shadow: none !important;
}
.login_enter_box_input.names{
  width: 352px;
  height: 52px;
  margin-top: 1px;
}
.login_enter_box_input .ant-input{
  font-size: 16px !important;
}
.login_enter_box_inputyzm{
  width: 240px;
  height: 60px;
  border: none;
  background: none !important;
  font-size: 16px;
  box-shadow: none !important;
}

.login_yzmpic{
  width: 125px;
  height: 60px;
  position: relative;
  border-radius:4px ;
  overflow: hidden;
}
.login_yzmpic img {
  width: 100%;
  height: 54px;
  margin-top: 3px;
  display: block;
}
.login_yzmbtn{
  width: 125px;
  height: 60px;
  position: absolute;
  top: 0px;
  left: 0px;
  cursor: pointer;
}
.login_yzmpic:hover{
  border-color: rgba(94, 121, 236, 1);
}
.login_submit_btn{
  width: 420px;
  height: 60px;
  background: rgba(94, 121, 236, 1);
  border: none;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  color: #ffffff;
  border-radius:4px ;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login_submit_btn:hover{
  background: rgb(70, 95, 207);
}
.bgBlue{
  background: rgb(70, 95, 207);
}
.login_tab{
  width: 400px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 10px;
  margin-bottom: 10px;
}
.login_tab span{
  display: block;
  height: 35px;
  font-size: 16px;
  color: rgba(172, 170, 187, 1);
  border-bottom:1px solid #ffffff ;
  cursor: pointer;
}
.login_tab span.actived{
  color: rgba(36, 43, 51, 1);
  border-color:rgba(94, 121, 236, 1) ;
}
.login_scanEwm{
  width: 236px;
  height: 236px;
  border: 1px solid #eeeeee;
  margin: 0 auto;
}
.login_scan_notice{
  width: 400px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  color: rgba(172, 170, 187, 1);
}
.login_scan_notice span{
  color: rgba(36, 43, 51, 1);
  padding: 0px 5px;
}

.footer{
  position: fixed;
  left: 0;
  right: 0;
  bottom: 10px;
  z-index: 999;
  text-align: center;
  color: #333;
}