#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}
#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}
#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}
.site-layout .site-layout-background {
  background: #fff;
}
.logo {
  text-align: center;
  color: #fff;
  height: 64px;
  line-height: 64px;
  font-size: 20px;
  letter-spacing: 1px;
}
.logo.small{
  font-size: 14px;
}
.ant-layout{
  height: 100%;
}
.menu_box{
  height: calc(100% - 64px);
  overflow: auto;
}
.welecome_right{
  width: auto;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  position: relative;
}
.username{
  color: #ffffff;
  font-size: 15px;
}
.userPhoto{
  width: 40px;
  height: 40px;
  border-radius:100% ;
  overflow: hidden;
  background: #ffffff;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.userPhoto img{
  width: 100%;
  height: 100%;
}
.dowmArror{
  width: 30px;
  height: 30px;
  font-size: 12px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.downBox{
  display: flex;
  align-items: center;
}
.wlecomText{
  color: #ffffff;
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: bold;
  margin-left: 15px;
}