* {
  margin: 0;
  padding: 0;
}

p {
  margin: 0 !important;
}

body {
  margin: 0;
  font-family: 'Microsoft YaHei', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  font-weight: 400;
  color: #75718d;
  min-width: 1440px;
}

a {
  color: #75718d;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.flex {
  display: flex;
}

.flex_just_betw {
  display: flex;
  justify-content: space-between;
}

.flex_just_around {
  display: flex;
  justify-content: space-around;
}

.flex_just_cen {
  display: flex;
  justify-content: center;
}

.flex_just_end {
  display: flex;
  justify-content: flex-end;
}

.flex_wrap {
  flex-wrap: wrap;
}

.flex_cen {
  align-items: center;
}

.pointer {
  cursor: pointer;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.DraggableTags {
  width: 100%;
  min-height: 116px;
}

.word_wrap {
  word-wrap: break-word;
  word-break: break-all;
}

.mt10 {
  margin-top: 10px;
}

.mt20 {
  margin-top: 20px;
}

.mt30 {
  margin-top: 30px;
}

.mb5 {
  margin-bottom: 5px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb15 {
  margin-bottom: 15px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb30 {
  margin-bottom: 30px;
}

.ml5 {
  margin-left: 5px;
}

.ml10 {
  margin-left: 10px;
}

.ml15 {
  margin-left: 15px;
}

.ml20 {
  margin-left: 20px;
}

.ml30 {
  margin-left: 30px;
}

.mr10 {
  margin-right: 10px;
}

.mr20 {
  margin-right: 20px;
}

.mr30 {
  margin-right: 30px;
}

.m10 {
  margin: 10px;
}

.m20 {
  margin: 20px;
}

.m30 {
  margin: 30px;
}

.pt10 {
  padding-top: 10px;
}

.pt20 {
  padding-top: 20px;
}

.pt30 {
  padding-top: 30px;
}

.pr10 {
  padding-right: 10px;
}

.pr20 {
  padding-right: 20px;
}

.pr30 {
  padding-right: 30px;
}

.pb10 {
  padding-bottom: 10px;
}

.pb20 {
  padding-bottom: 20px;
}

.pb30 {
  padding-bottom: 30px;
}

.pl10 {
  padding-left: 10px;
}

.pl20 {
  padding-left: 20px;
}

.pl30 {
  padding-left: 30px;
}

.p10 {
  padding: 10px;
}

.p20 {
  padding: 20px;
}

.p30 {
  padding: 30px;
}

.tx_c {
  text-align: center;
}

.tx_l {
  text-align: left;
}

.tx_r {
  text-align: right;
}

.corfff {
  color: #fff;
}

.cor1 {
  /* 标题颜色 */
  color: #242b33;
}

.cor2 {
  /* 主要内容颜色 */
  color: #75718d;
}

.cor3 {
  /* 说明内容颜色 */
  color: #acaabb;
}

.cor4 {
  /* 主要文字颜色 */
  color: #5E79EC;
}

.bold {
  font-weight: bold;
}

.fs12 {
  font-size: 12px !important;
}

.fs14 {
  font-size: 14px !important;
}

.fs16 {
  font-size: 16px !important;
}

.fs18 {
  font-size: 18px !important;
}

.fs20 {
  font-size: 20px !important;
}

.fs22 {
  font-size: 22px !important;
}

.fs24 {
  font-size: 24px !important;
}

.fs26 {
  font-size: 26px !important;
}

.fs30 {
  font-size: 30px !important;
}

.bgf {
  background-color: #fff !important;
}

.bor {
  border: 1px solid #eee;
}

.bor_t {
  border-top: 1px solid #eee;
}

.bor_l {
  border-left: 1px solid #eee;
}

.bor_r {
  border-right: 1px solid #eee;
}

.bor_b {
  border-bottom: 1px solid #eee;
}

.shadow {
  box-shadow: 0 4px 20px 0 rgba(0, 26, 79, 0.14);
}


/*滚动条样式*/
.scrollbar::-webkit-scrollbar,
body::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 0px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 15px;
}

.scrollbar::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 6px;
  -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.3);
  background: rgba(0, 0, 0, 0.3);
}

.scrollbar::-webkit-scrollbar-track,
body::-webkit-scrollbar-thumb {
  /*滚动条里面轨道*/
  -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.3);
}

/*复选框圆角*/
.ant-checkbox-inner,
.ant-checkbox-inner {
  border-radius: 2px !important;
}

/*表单label字体颜色*/
.ant-form-item-label>label {
  font-size: 14px;
  font-weight: bold;
  color: #75718D !important;
}

/*radio label字体设置*/
.ant-radio-wrapper {
  color: #0D0F35;
  font-size: 14px;
}

/*input 单位字体设置*/
.ant-input-suffix {
  font-weight: bold;
  color: #0D0F35;
  font-size: 14px;
}

input,
textarea,
.ant-input-affix-wrapper,
.ant-select-selector,
.ant-select-dropdown,
.ant-picker {
  border-radius: 4px !important;
}

.ant-radio-button-wrapper:first-child {
  border-radius: 4px 0 0 4px !important;
}

.ant-radio-button-wrapper:last-child {
  border-radius: 0 4px 4px 0 !important;
}

/*弹窗标题加粗*/
.ant-modal-title {
  font-weight: bold !important;
  color: #0D0F35;
}

/*表单错误提示定位，不然会占据文档流*/
.formStyle {
  padding: 0px 25px;
  position: relative;
}

.formItemStyle {
  padding-bottom: 30px !important;
  margin-right: 30px !important;
  position: relative;
  margin-left: 30px !important;
}

.formItemStyle .ant-form-item {
  margin-right: 0px !important;
}

.formItemStyle .ant-picker {
  padding: 4px 9px 4px !important;
}

.ant-form-inline .ant-form-item-with-help {
  margin-bottom: 0px !important;
}

.ant-form-item-control {
  position: relative;
}

.ant-form-item-explain {
  position: absolute;
  left: 0;
  font-size: 13px !important;
  bottom: -25px;
}

.ant-form-item-label {
  min-width: 50px !important;
  text-align: right;
  letter-spacing: 2px;
}
/*改变清除按钮的颜色*/
.ant-cascader-picker-clear,
.ant-select-clear,
.ant-input-clear-icon,
.ant-input-textarea-clear-icon {
  color: #5E79EC !important;
}

.ant-dropdown-menu-item:hover {
  background: #5E79EC !important;
  color: #ffffff !important;
}

.sysListContent{
  width: 100%;
  height: auto;
  border-radius:10px ;
  background: #ffffff;
}
.sysListContent_title{
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-indent: 20px;
  border-bottom:1px solid #eeeeee ;
  font-size: 16px;
  color: rgba(13, 15, 53, 1);
}
.sysListContent_cons{
  width: 100%;
  max-height: calc(100vh - 160px);
  overflow-x: hidden;
  overflow-y: auto;
  clear: both;
}

.sysListContent_item{
  width: 308px;
  height: auto;
  float: left;
  margin-left: 55px;
  margin-top: 30px;
  cursor: pointer;
}
.sysListContent_item span{
  display: block;
  width: 308px;
  height: 157px;
  border: 1px solid #eeeeee;
  border-radius:4px ;
  overflow: hidden;
}
.sysListContent_item span img{
  display: block;
  width: 308px;
  height: 157px;
}
.sysListContent_item i{
  display: block;
  width: 308px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 15px;
  color: rgba(13, 15, 53, 1);
  font-style: normal;
}
.sysListContent_item:hover i {
  color: rgba(94, 121, 236, 1);
}
.sysListContent_item:hover span {
  border-color: rgba(94, 121, 236, 1);
}
.loadingStyle {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 500;
  background: rgba(255, 255, 255, 0.6);
}
